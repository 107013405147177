import { MotionImage } from "components/MotionImage";
import { Page } from "components/Page";
import { PageHeader } from "components/PageHeader";
import { Photo } from "components/Photo";
import { motion } from "framer-motion";
import React from "react";
import { Helmet } from "react-helmet";

const variants = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
      delay: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const paragraphs = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 1 },
  },
  hidden: {
    opacity: 0,
    y: 0,
  },
};

export default function AboutPage() {
  return (
    <Page>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={"Rockstar Promotions"}
        meta={[
          {
            name: `description`,
            content: "Rockstar Promotions",
          },
          {
            property: `og:url`,
            content: `https://rockstarpromotions.co.uk`,
          },
          {
            property: `og:title`,
            content: "Rockstar Promotions",
          },
          {
            property: `og:description`,
            content: "",
          },
          {
            property: `og:image`,
            content: ``,
          },
        ]}
      />
      <section className="mt-8 mb-24">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row gap-2 md:justify-between mt-8">
            <div className="max-w-xl mb-8">
              <h1 className="mb-8 md:mt-8">ABOUT US</h1>
              <p>
                Established in 2007, the award winning Rockstar Promotions are
                one of the leading entertainment event companies in the UK.
              </p>
              <p>
                We run several club nights including LetsDisko, Supersonic and
                Electric 80’s, as well as Festivals such as Fresh Festival and
                Halloween Festival! We also run other large scale events,
                including Highcross Student Sessions shopping event, a Student
                Race Day at Leicester Racecourse, and Athena Sit Down Social
                events, as well as offering our event services to a number of
                clients and venues.
              </p>
            </div>
            <div className="relative flex justify-items-center md:justify-end flex-1">
              <Photo
                className="relative w-72 transform translate-x-4 md:translate-x-20"
                bgColor="var(--yellow)"
                src="about-1.jpg"
                offsetX={-16}
                offsetY={16}
              >
                <MotionImage
                  className="absolute w-24 -bottom-12 -left-6"
                  src={"/icon-note-01.png"}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    transition: {
                      duration: 0.75,
                      delay: 0.9,
                    },
                  }}
                  initial={{ opacity: 0, scale: 0.7 }}
                />
              </Photo>
              <Photo
                className="relative transform w-72 translate-y-20 md:translate-y-32"
                bgColor="var(--blue)"
                src="about-2.jpg"
              >
                <MotionImage
                  className="absolute w-16 left-0 -top-8 md:-top-28 md:left-10"
                  src={"/icon-lightning.png"}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    transition: {
                      duration: 0.95,
                      delay: 0.5,
                    },
                  }}
                  initial={{ opacity: 0, scale: 0.6 }}
                />
                <MotionImage
                  className="absolute w-16 -bottom-8 right-8"
                  src={"/icon-note-02.png"}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    transition: {
                      duration: 0.85,
                      delay: 0.75,
                    },
                  }}
                  initial={{ opacity: 0, scale: 0.6 }}
                />
              </Photo>
            </div>
          </div>
          <div />
        </div>
      </section>
    </Page>
  );
}
